s<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: googleSignIn.vue
Description: This file contains the button used for signing in to pondlogs using google credentials
-->
<template>
  <er-button
    size="small"
    class="btn-login--google"
    id="googleBtn"
    :disabled="true"
  >
    <div style="display: flex; align-items: center; justify-content: center">
      <img
        class="image__google--icon img-icon"
        src="@/assets/google.svg"
        :alt="this.$t('google')"
      />
      <span class="image__google--text">{{ $t("Comn_google_sign_in") }}</span>
    </div>
  </er-button>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin.js";
import { userTypes } from "@/middleware/pageAccessManager";
export default {
  mixins: [errorHandlerMixin],
  mounted() {
    this.loadGoogleLogin();
  },
  methods: {
    loadGoogleLogin() {
      // eslint-disable-next-line
      gapi.load("auth2", () => {
        // Retrieve the singleton for the GoogleAuth library and set up the client.
        // eslint-disable-next-line
        this.auth2 = gapi.auth2.init({
          client_id:
            "856140418745-buc1tsh1isif23egvd0r4gkpn6t8v7lh.apps.googleusercontent.com",
          cookiepolicy: "single_host_origin"
          // Request scopes in addition to 'profile' and 'email'
          // scope: 'additional_scope'
        });
        this.attachSignin(document.getElementById("googleBtn"));
      });
    },
    attachSignin(element) {
      this.auth2.attachClickHandler(
        element,
        {},
        googleUser => {
          const authReponse = googleUser.getAuthResponse(true);
          // const GoogleAuth = gapi.auth2.getAuthInstance();
          this.$store
            .dispatch("auth/storeGoogleAccessToken", authReponse.access_token)
            .then(async response => {
              this.$notify({
                title: this.$t("Usrs_success_msg"),
                message: this.$t("User_sigin_success"),
                duration: 1500,
                type: "success"
              });
              // this.$router.push("/user");
              const userType = response.data.user_details.user_type;
              if (userType === userTypes.super_admin) {
                this.$router.push("/admin/customers");
              } else {
                this.$router.push("/user");
              }
            });
        },
        error => {
          this.ehm__errorMessages(error, false);
          console.error(JSON.stringify(error, undefined, 2));
        }
      );
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/mixins";
.btn-login--google {
  height: 32px;
  // padding: 5px 8px;
  box-shadow: 0px 1px 2px #00000036;
  // border-radius: 10px;
  // background-color: #3f93d8;
  span {
    display: flex;
    flex-direction: row;
    .image__google--icon {
      background: white;
      border-radius: 4px;
      padding: 2px;
    }
    .image__google--text {
      @include responsiveProperty(font-size, 1rem, 0.8rem, 0.8rem);
      // height: 20px;
      color: #000000;
      @include responsiveProperty(line-height, 1, 1.5, 1);
      padding-left: 12px;
      opacity: 0.8;
    }
  }
  // &.el-button:focus,
  // &.el-button:hover {
  //   color: #409eff;
  //   border-color: #c6e2ff;
  //   background-color: #0259c4;
  // }
}
</style>
