<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: card.vue
Description: This file contains the card component commonly used for user registration, signin, forgot password, reset password and other modules in the authentication module
-->
<template>
  <el-card class="card">
    <div slot="header">
      <!-- <div class="card-header"> -->
      <slot name="title">
        <h2>title</h2>
      </slot>
      <!-- </div> -->
      <span>
        <div class="card-subtitle">
          <slot name="subtitle"></slot>
        </div>
      </span>
    </div>
    <slot name="form"></slot>
  </el-card>
</template>

<script>
export default {};
</script>

<style lang="scss">
.card {
  @include respond-to(medium-screens) {
    height: 100%;
  }
  @include respond-to(wide-screens) {
    height: 100%;
  }
  // height: 100%;
  &.el-card {
    z-index: 32;
    border-radius: 20px !important;
  }
  &.el-card.is-always-shadow,
  &.el-card.is-hover-shadow:focus,
  &.el-card.is-hover-shadow:hover {
    box-shadow: 3px 3px 13px 0px rgba(0, 0, 0, 0.1);
  }
  .el-card__header {
    justify-content: center;
    display: flex;
    // margin: 2px;
    padding: 1.6em 5em;
    border-bottom: 0px;
    h2 {
      width: 100%;
      color: #359a10;
      font-weight: 400;
      text-align: center;
      &:after {
        display: block;
        content: "";
        border: 2px solid #45baf0;
        border-radius: 0px 0px 20px 20px;
        width: 3rem;
        margin: 3px auto 0px auto;
        background-color: #45baf0;
      }
    }
  }
  .card-subtitle {
    text-align: center;
  }
  .el-card__body {
    display: flex;
    flex-direction: column;
    // height: 100%;
    padding: 0rem 5rem 2rem 4rem;
    box-sizing: border-box;
    text-align: center;
    margin: auto;
  }
}
</style>
